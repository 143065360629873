import { typeMappings } from './paths'
import { env } from '@/env'
import type { INode } from './ris-types'

export const domain = env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  ? 'https://gesetzefinden.at'
  : (env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
      ? (env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'dev'
          ? 'https://dev.gesetzefinden.at'
          : `https://${env.NEXT_PUBLIC_VERCEL_BRANCH_URL || env.NEXT_PUBLIC_VERCEL_URL}`)
      : 'http://localhost:3000')
export const isDev = env.NEXT_PUBLIC_VERCEL_ENV !== 'production'

export const DAY_IN_SECONDS = 60 * 60 * 24

const urlifyCache = {}
/**
 * Makes text suited to be used in URLs
 */
export function urlify (text: string, { lowerCase = true } = {}): string {
  if (urlifyCache[text]) {
    return urlifyCache[text]
  }

  lowerCase && (text = text.toLowerCase())

  urlifyCache[text] = encodeURIComponent(text
  // "u00A0" is a non-breaking space, which is used sometimes, so handle it as well (%C2%A0). E.g. "/bundesrecht/verordnungen/eregv%C2%A02022"
    .replace(/ |\u00A0|\/|:/g, '-')
    .replace(/\.|,|\(|\)/g, '')
    .replace(/ä/g, 'a') // Replace with simple letter "a" instead of "ae", because that's what Algolia understands
    .replace(/ö/g, 'o') // Umlaut-removal inspired by https://stackoverflow.com/a/40015184/8464260
    .replace(/ü/g, 'u')
    .replace(/ß/g, 'ss')
    .replaceAll('§', 'para'))

  return urlifyCache[text]
}

const determUrlifyCache = {}

/**
 * Custom version of the `urlify()` function which only does deterministic changes, which can be reversed to the original string with `resolveUrlify()`
 */
export function deterministicUrlify (text: string): string {
  if (determUrlifyCache[text]) {
    return determUrlifyCache[text]
  }

  determUrlifyCache[text] = encodeURIComponent(text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/\./g, '')
    .replaceAll('§', 'para'))

  return determUrlifyCache[text]
}

/**
 * Reverses the urlified form of a `deterministicUrlify()` string into its original form
 */
export function resolveUrlify (text: string): string {
  return decodeURIComponent(text
    .replaceAll('-', ' ')
    .replace('para', '§')
    .replace('anl', 'Anl.')
    .replace('art', 'Art.'))
}

interface Options {
  /**
   * Either the normId or its abbreviation
   *
   * @example ABGB
   * @example 10002296
   */
  norm?: string
  /**
   * Additional differentiator for duplicate abbreviations
   */
  abbrNr?: number
  enumeration?: string
  enumerationHash?: string
  // FIXME: Remove when Typesense is done
  lowerCase?: boolean
}
/**
 * Builds a full URL path for a given category
 *
 * @example Category "V" gives "/bundesrecht/verordnungen"
 * @example Category "BG" gives "/bundesrecht/bundesgesetze"
 */
export function getUrl (category: string | string[], { norm, abbrNr, enumeration, enumerationHash, lowerCase = true }: Options = {}): string {
  const mapping = typeof category === 'string' ? typeMappings[category.toLowerCase()] : { category: category[0], subCategory: urlify(category[1]) }
  if (mapping === undefined) {
    throw Error(`NO_MAPPING - There is no mapping for category "${category.toString()}" (norm "${norm as string}")`)
  }

  // eslint-disable-next-line
  let normUrl = `/${mapping.category}/${mapping.subCategory}`
  if (!norm) {
    return normUrl
  }
  normUrl += `/${urlify(norm, { lowerCase })}${abbrNr ? `_${abbrNr}` : ''}`

  if (enumeration) {
    normUrl += `/${deterministicUrlify(enumeration)}`
  } else if (enumerationHash) {
    // Use urlify instead of deterministicUrlify, because deterministicUrlify() is does not currently work for decisions ("/" should be translated to "-", but that is already reserved for spaces " ")
    normUrl += `#${urlify(enumerationHash)}`
  }

  return normUrl
}

export function getDecisionUrl (decision) {
  return `/judikatur/${urlify(getJudikaturApplikation(decision) ?? '')}/${urlify(decision.normId)}`
}

export function getJudikaturApplikation (decision) {
  return decision.Applikation === 'Justiz' ? (decision.institution?.[0] ?? decision.Gericht ?? decision.Organ) : (decision.institution?.[0] ?? decision.Applikation)
}

const formatter = new Intl.DateTimeFormat('de', { day: '2-digit', month: 'long', year: 'numeric' })
export function formatDate (date?: string): string {
  if (!date) return formatter.format(new Date())
  return formatter.format(new Date(date))
}

const formatter1 = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 1,
})
const formatter2 = new Intl.NumberFormat('de', {
  maximumFractionDigits: 0,
})
export function formatNumber (number: number, { short } = { short: false }) {
  if (short) {
    return formatter1.format(number).replace('.', ',')
  }

  return formatter2.format(number)
}

let visitorId
/**
 * Returns the visitor ID from Matomo (also known as UserID)
 * See https://forum.matomo.org/t/how-can-i-retrieve-visitor-id-from-javascript/16345
 * And https://forum.matomo.org/t/retrieve-visitor-id-for-gdpr-requests/38332/2
 */
export async function getVisitorId (): Promise<string> {
  if (visitorId) return visitorId

  // @ts-expect-error global var
  await window.mtmReady

  return await new Promise(resolve => {
    // @ts-expect-error global var
    window._paq?.push([
      function () {
        visitorId = this.getVisitorId() as string
        resolve(visitorId)
      },
    ])
  })
}

// From https://stackoverflow.com/a/58110124
export function nonNullable<T> (value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

/**
 * Returns a pure text representation of the content item
 * From https://github.com/syntax-tree/nlcst-to-string/blob/main/index.js
 */
export function toString (node: string | string[] | INode[] | INode, separator = ''): string {
  if (node === undefined || node === null) {
    console.error(`Expected node, not ${JSON.stringify(node)} (type ${typeof node})`)
    throw new Error(`Expected node, not ${JSON.stringify(node)} (type ${typeof node})`)
  }

  if (typeof node === 'string') return node
  else if (Array.isArray(node)) {
    return node.map((child) => toString(child, separator)).join(separator)
  } else if (Array.isArray(node.children)) {
    return node.children.map((child) => toString(child, separator)).join(separator)
  } else if (typeof node.value === 'string') return node.value
  else return ''
}

/**
 * Splits an array into groups
 *
 * @see https://stackoverflow.com/a/64489535/8464260
 * @param array The array to apply grouping to
 * @param groupingFn A function to decide which group to put an item into. Is called for every item in the array, the returned string is used as the group key which the item should be grouped with.
 * @returns An object with the groups
 */
export const groupBy = <T>(array: T[], groupingFn: (v: T) => string) => {
  return array.reduce<Record<string, T[]>>((acc, value) => {
    (acc[groupingFn(value)] ||= []).push(value)
    return acc
  }, {})
}
